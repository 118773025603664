<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>
        <div style="width:100%; display:flex;">
          <ion-img style="height: 40px;" src="/assets/logo.png"></ion-img>
        </div>
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">
            <div style="width:100%; display:flex;">
              <ion-img style="width: 30px; height: 30px;" src="/assets/icon/momentproof_logo.png"></ion-img>
              <span style="line-height: 30px;padding: 0px 10px;">MomentProof</span>
            </div>
          </ion-title>
        </ion-toolbar>
      </ion-header>
      <div style="padding-top: 150px!important;" id="container">

        <ion-grid>
          <ion-row>
            <ion-col size="12">
              <div class="text-100" style="width: 100%; text-align: left; margin: 20px 0px 10px 0px;">Delivery Proof</div>
              <ion-img class="proof-image"
                       @click="isImageFocus = !isImageFocus"
                       style="width: fit-content; height: 100%; max-height: 300px;" v-if="data" :src="data.image"></ion-img>
            </ion-col>
            <ion-col size="12">
              <div class="text-100" style="width: 100%; text-align: left; margin: 20px 0px 10px 0px;">Delivery Location</div>
              <div style="width: 100%; max-width: 300px; height: 300px;" id="map"></div>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-text v-if="data">
                <ion-grid>
                  <ion-row>
                    <ion-col class="col-left"><span class="text-100">Delivery Date:</span> </ion-col>
                    <ion-col class="col-left"><span class="text-100">{{ formatDate(data?.timestamp) }}</span> </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col class="col-left"><span class="text-100">User:</span> </ion-col>
                    <ion-col class="col-left"><span class="text-100">{{ $route.params.userId }}</span> </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col class="col-left"><span class="text-200" style="color: #00DE3E;">Verified by MomentProof</span></ion-col>
                  </ion-row>
                </ion-grid>
              </ion-text>
            </ion-col>
            <ion-col>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-content>
    <div v-if="isImageFocus" class="zoom-modal">
      <div class="zoom-close" @click="isImageFocus = !isImageFocus"> X </div>
      <img class="zoom-image" :src="data.image">
    </div>
  </ion-page>
</template>

<script lang="js">
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonImg, IonIcon} from '@ionic/vue';
/* eslint-disable */
import {firestore, doc, getDoc} from '@/services/firebase';
import {defineComponent} from 'vue';
import {GoogleMap} from '@capacitor/google-maps';
import moment from 'moment';
import { VueImageZoomer } from 'vue-image-zoomer';

const apiKey = 'AIzaSyDr5Oa1AKljUPG2Ms0VOMHWmH2Ldzy7-Ck';

export default defineComponent({
  name: 'HomePage',
  components: {
    VueImageZoomer,
    IonContent,
    IonHeader,
    IonIcon,
    IonImg,
    IonPage,
    IonTitle,
    IonToolbar
  },
  data() {
    return {
      data: null,
      map: null,
      isImageFocus: false
    }
  },
  async mounted() {
    const mapRef = document.getElementById('map');
    console.log('mapRef', mapRef);
    if (mapRef) {
      this.map = await GoogleMap.create({
        id: 'my-map', // Unique identifier for this map instance
        element: mapRef, // reference to the capacitor-google-map element
        apiKey: apiKey, // Your Google Maps API Key
        config: {
          disableDefaultUI: true,
          zoomControl: true,
          center: {
            // The initial position to be rendered by the map
            lat: 33.6,
            lng: -117.9,
          },
          zoom: 18, // The initial zoom level to be rendered by the map
        },
      });
    }
    this.getData();
  },
  methods: {
    formatDate(timestamp) {
      return moment(timestamp, 'x').format('DD/MM/yyyy hh:mm');
    },
    getData() {
      const name = this.$route.params.userId + "-" + this.$route.params.id;
      const cityRef = doc(firestore, 'deliveries', name);
      getDoc(cityRef).then((snapshot) => {
        this.data = snapshot.data();
        this.map.addMarker({
          coordinate: {
            lat: this.data.coordinates.lat,
            lng: this.data.coordinates.long,
          }
        });
        this.map.setCamera({
          coordinate: {
            lat: this.data.coordinates.lat,
            lng: this.data.coordinates.long,
          }
        });
      });


    }
  }
});
</script>

<style scoped>

  .proof-image, .proof-image::part(image) {
    height: fit-content;
    max-width: 300px;
    max-height: 300px;
    width: fit-content;
    cursor: pointer;
  }

  .zoom-close {
    position: absolute;
    width: 30px;
    height: 30px;
    line-height: 27px;
    font-size: 27px;
    top: 0px;
    right: 30px;
    color: white;
    cursor: pointer;
  }

  .zoom-modal {
    width: calc(100vw - 0px);
    height: calc(100% - 0px);
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .proof-image img {
    height: fit-content;
    max-width: 300px;
    max-height: 300px;
    width: fit-content;
    cursor: pointer;
  }

  .text-100 {
    font-size: 18px;
  }
  .text-200 {
    font-size: 24px;
    font-weight: bold;
  }

  .col-left {
    display: flex;
    justify-content: left;
  }

#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>
