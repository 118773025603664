// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getFirestore, doc, setDoc, getDoc} from "firebase/firestore";
import {getStorage, ref, uploadBytes, uploadString, getDownloadURL } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBrhgEL4T4mtnX7cCSayDUcHZEgOZAtGIs",
    authDomain: "momentproof-dev.firebaseapp.com",
    projectId: "momentproof-dev",
    storageBucket: "momentproof-dev.appspot.com",
    messagingSenderId: "878766190276",
    appId: "1:878766190276:web:a4262a39f32f9853176391",
    measurementId: "G-70SLFE4K3C"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const firestore = getFirestore(app);

export {firestore, storage, ref, uploadBytes, uploadString, getDownloadURL, doc, setDoc, getDoc};