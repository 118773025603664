import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Success from "@/views/Success.vue";
import Fail from "@/views/Fail.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:userId/:id',
    name: 'Success',
    component: Success
  },
  {
    path: '/:userId/:id/1',
    name: 'Fail',
    component: Fail
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
